<template>
  <div>
    <div style="max-width: 400px; margin: auto">
      <img src="../../assets/logo/logo.png" class="logo" />

      <div class="align-items-center justify-content-center">
        <div style="text-align:center">
          <span style="font-weight: bold; font-size: 1.5rem">Welcome</span><br />
          <span style="font-size: 1.2rem"> Please Sign in to continue </span>
        </div>

        <form class="container" style="margin-top:34px">
          <div class="form-group">
            <div class="row px-2">Email/Username</div>
            <!-- <label for="email"  >Email address</label> -->
            <input
              type="email"
              class="form-control inputborder"
              id="email"
              v-model="user.username"
            />
          </div>

          <div class="form-group">
            <div class="row px-2">Password</div>
            <!-- <label for="password">Password</label> -->
            <input
              type="password"
              class="form-control inputborder"
              id="password"
              v-model="user.password"
            />
          </div>
          <CAlert
            style="color: red"
            :show.sync="currentAlertCounter"
            closeButton
          >
            Can't find user with this username or password!
          </CAlert>
          <div class="login-btn">
            <button
              v-on:click="Authenticate"
              type="button"
              class="btn btn-dark rounded-pill"
              style="width: 100%"
            >
              Sign in
              <span style="display: none" id="login-spinner">
                <span style="float: right">
                  <CSpinner style="color: #130d25" grow />
                </span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AuthModel from "../../models/auth";
export default {
  name: "Login",
  data() {
    return {
      user: new AuthModel(),
      currentAlertCounter: 0,
    };
  },
  methods: {
    Authenticate: function () {
      const logBtnEle = document.getElementById("login-spinner");
      if (this.user.username && this.user.password) {
        logBtnEle.style = "display:inline";
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            this.currentAlertCounter = 4;
            logBtnEle.style = "display:none";
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.inputborder {
  border-left: 4px solid;
  box-shadow: 0px 2px 17px #d0cfcf;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.inputborder:focus {
  box-shadow: 0px 2px 17px #d0cfcf;
}

.dont-have-account {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#keepme {
  border-radius: 3.25em;
  background-color: #d5d5d5;
  color: #d5d5d5;
  border: #d5d5d5;
  box-shadow: none;
}
#keepme:checked {
  box-shadow: none;
  color: #00db58;
  background-color: #00db58;
}

/* .row {
  display:inline-block;
}
.col-12 {
  width: 100%;
}
.col-6 {
  width: 50%;
  display: block;

}
*/
.logo {
  height: 120px;
  margin: 20px auto;
  display: block;
}
.login-btn {
  margin-top: 50px;
}

button {
  font-size: 20px;
  font-weight: 600;
}
</style>
